import React, { useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import {
  Link
} from 'react-router-dom'
import { Modal } from "react-bootstrap";

import { useDispatch, useSelector } from 'react-redux';
import { CheckCircleTwoTone } from '@ant-design/icons';


const ConsentSuccess = (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const callback = useSelector(state => state.common.callback)
  //console.log("callback->" + JSON.stringify(callback));

  // Extract the query parameter from the URL
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code'); // This will extract the 'code' parameter

  // const click = () =>{
  //   // window.open(`http://localhost:3000/onb3`, '_blank').focus();
  //   // window.close()
  //   // navigate('/onb3')
  //   alert("callbackURL" + callback);
  //   location.href = callback;
  // }

  const click = () => {
    const callbackURL = callback.callback; // Extract the callback URL
    //alert("callbackURL: " + callbackURL); // Alert the extracted URL
    //location.href = callbackURL; // Redirect to the callback URL
  }


  // useEffect(() => {
  //  setTimeout(click, 5000);

  // }, []);

  return (
    <div className="App App-header ">

      <Modal.Header >
        <div className="d-flex align-items-center">

        </div>

      </Modal.Header>

      <Modal.Body  >
        <div className="p-3 m-3" style={{ marginVertical: 50 }}>
          <CheckCircleTwoTone className="m-2" style={{ fontSize: '50px' }} />
          <h1 style={{ fontSize: '20px', color: 'white' }}>Welcome !!</h1>
        </div>
      </Modal.Body >

    </div>
  );
}

export default ConsentSuccess;
