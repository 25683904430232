import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from "@mui/material";
import { BallTriangle } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CreateConsent, getProfileData, getPreferenceCenterThemeByGroupId, getTemplateDetailByConsentGroupId, generateToken, saveParamsToState, callRedirectAPI } from '../../store/actions/CommonAction';
import base64 from 'base-64';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Accordion from 'react-bootstrap/Accordion';
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import queryString from 'query-string';
import '../../style.css/toggle.css';

const Main = (props) => {
  const clientIdcallRedirectAPI = useSelector(state => state.common.clientId)
  const referenceId = useSelector(state => state.common.referenceId)
  const location = useLocation();
  const encryptedData = location.pathname.substring(1);
  const [checked, setChecked] = useState(false);

  // Decode base64 and parse the original encryptedData
  let originalEncryptedData = null;
  try {
    const decodedData = atob(encryptedData); // Decode base64 string
    originalEncryptedData = JSON.parse(decodedData); // Parse the JSON string
    console.log("Original Encrypted Data:", originalEncryptedData);
  } catch (error) {
    console.error("Failed to decode or parse encryptedData:", error);
  }

  const queryParams = new URLSearchParams(location.search);

  const tempDetails = useSelector(state => state.common.tempDetails);
  const preferenceCenterTheme = useSelector(state => state.common.theme);
  const profileData = useSelector(state => state.common.profileData);
  const appName = useSelector(state => state.common.App_Name);
  const Logo = useSelector(state => state.common.logo);

  // Define the default logo image path
  const defaultLogo = '/Jio-Logo-Colour-Reliance-Midnight-512.png';

  // Determine the logo to display
  const logoToDisplay = Logo ? Logo : defaultLogo;


  const getConsentGroupDetailsByGroupId = useSelector(state => state.common.getConsentGroupDetailsByGroupId);
  const consentGroupName = useSelector(state => state.common.consentGroupName);
  const templates = useSelector(state => state.common.templates);


  const [backgroundColor, setBackgroundColor] = useState('#fff');
  const [topDescriptionColor, setTopDescriptionColor] = useState('#000');

  const [bottomDescriptionColor, setBottomDescriptionColor] = useState('#000');

  const [buttonBackgroundColor, setButtonBackgroundColor] = useState('#000');
  const [buttonColor, setButtonColor] = useState('#fff');

  const [Panel1BackgroundColor, setPanel1BackgroundColor] = useState('#fff');
  const [Panel1color, setPanel1Color] = useState('#000');

  const [Panel2BackgroundColor, setPanel2BackgroundColor] = useState('#fff');
  const [Panel2color, setPanel2Color] = useState('#000');

  // Access the first template and then its templateDetails to get dataConsumer
  const firstTemplate = Array.isArray(templates) && templates.length > 0 ? templates[0] : null;
  const dataConsumerString = firstTemplate && firstTemplate.templateDetails && firstTemplate.templateDetails.dataConsumer
    ? firstTemplate.templateDetails.dataConsumer.join(', ')
    : 'No data consumer available';

  // const logo = useSelector(state => state.common.logo);
  const consentText = useSelector(state => state.common.consentText);
  const [topDescVal, setTopDescVal] = useState('');
  const [bottomDescVal, setBottomDescVal] = useState('');
  const appNameRedirectApiCall = useSelector(state => state.common.appName);
  const themeRedirectApiCall = useSelector(state => state.common.theme);
  //alert("themeRedirectApiCall->"+ JSON.stringify(themeRedirectApiCall));

  const noticeFileUrl = useSelector(state => state.common.data);
  //let baseVal = `data:application/pdf;base64,${noticeFileUrl}`;


  console.log(' by me-->' + JSON.stringify(noticeFileUrl));



  // console.log('appNameRedirectApiCall-->' + JSON.stringify(appNameRedirectApiCall));
  // console.log('themeRedirectApiCall-->' + JSON.stringify(themeRedirectApiCall));
  // console.log('templates-->' + JSON.stringify(templates));

  const [toggleStates, setToggleStates] = useState(new Array(getConsentGroupDetailsByGroupId.length).fill(false));
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);
  const [showToggle, setShowToggle] = useState(false);
  const [templateIdArray, setTemplateIdArray] = useState([]);
  const [showModal, setShowModal] = useState(true);
  const [blobUrl, setBlobUrl] = useState('');
  const [checkedStates, setCheckedStates] = useState({});
  // const encryptedData = useSelector(state => state.common.encryptedData);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const avatarImage = (Logo == undefined || Logo == null) ? accountcircle : Logo;

  // const noticeFileUrl = "/sample.pdf";

  let consentGroupId = '';
  let customerId = '';
  let clientId = '';
  let clientSecret = '';

  const toggleLoader = () => {
    setTimeout(() => setLoader(!loader), 3000);
  }

  const checkOtherEntitiesCheck = (templateId) => {
    //alert("templateId->" + templateId)
    // Toggle checked state of the clicked switch
    setCheckedStates((prevState) => ({
      ...prevState,
      [templateId]: !prevState[templateId],
    }));

    if (templateIdArray.includes(templateId)) {
      const updatedTemplateIdArray = templateIdArray.filter(v => v !== templateId);
      setTemplateIdArray(updatedTemplateIdArray);
    } else {
      setTemplateIdArray([...templateIdArray, templateId]);
    }
    //setChecked(!checked);
  }

  // const accept = async () => {
  //   try {
  //     document.getElementById("btnfont").style.display = "none";
  //     document.getElementById("consentLoader").style.display = "block";
  //     await dispatch(generateToken());
  //     await dispatch(CreateConsent(templateIdArray, referenceId))
  //     await navigate(`/consent`);
  //   } catch (err) {
  //     console.log('error in screen ' + error + JSON.stringify(error));
  //   }
  // }

  const accept = async () => {
    try {
      // Hide button and show loader
      document.getElementById("btnfont").style.display = "none";
      document.getElementById("consentLoader").style.display = "block";

      // Fetch all active template IDs and add to templateIdArray
      const activeTemplateIds = templates
        .filter(template => template.templateDetails.mandatory)
        .map(template => template.templateId);

      //alert("activeTemplateIds->" + JSON.stringify(activeTemplateIds));

      // Filter out any IDs that are already in the templateIdArray
      const newTemplateIds = activeTemplateIds.filter(id => !templateIdArray.includes(id));

      // Set the updated templateIdArray
      if (newTemplateIds.length > 0) {
        setTemplateIdArray(prevArray => {
          const updatedArray = [...prevArray, ...newTemplateIds];

          // Execute dispatches and navigation here after the state update
          dispatch(generateToken())
            .then(() => dispatch(CreateConsent(updatedArray, referenceId)))
            .then(() => navigate(`/consent`))
            .catch(err => console.log('Error in dispatch: ' + err + JSON.stringify(err)));

          return updatedArray; // This returns the new state
        });
      } else {
        // If no new IDs, just execute the dispatches
        await dispatch(generateToken());
        await dispatch(CreateConsent(templateIdArray, referenceId));
        await navigate(`/consent`);
      }
    } catch (err) {
      console.log('Error in screen: ' + err + JSON.stringify(err));
    }
  };


  const pdfURL = () => {
    //alert("noticeFileUrl meeeeeee->" + JSON.stringify(noticeFileUrl));
    if (noticeFileUrl) {
      try {
        // Decode the base64 string
        const byteCharacters = atob(noticeFileUrl);

        // Convert the base64 string to a binary string
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        // Convert binary string to an array buffer
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        // Create a URL for the blob and update state
        const newBlobUrl = URL.createObjectURL(blob);
        setBlobUrl(newBlobUrl); // Update state

        console.log("Blob URL created:", newBlobUrl);

      } catch (error) {
        console.error("Error decoding base64 or creating blob URL:", error);
      }
    } else {
      console.log("Something went wrong: noticeFileUrl is undefined or null");
    }
  };


  const toggleMenu = () => {
    setShowToggle(!showToggle);
  }

  const toggleMenuShare = useCallback(
    (index) => {
      const updatedToggleStates = [...toggleStates];
      updatedToggleStates[index] = !updatedToggleStates[index];
      setToggleStates(updatedToggleStates);
    },
    [toggleStates]
  );

  const getDetailByConsentGroupId = async () => {
    try {
      if (consentGroupId) {
        await dispatch(getTemplateDetailByConsentGroupId(consentGroupId));
        if (getConsentGroupDetailsByGroupId) {
          const mandatoryTemplateIds = getConsentGroupDetailsByGroupId
            .filter(singleTemplate => singleTemplate.templateDetails.mandatory === true)
            .map(singleTemplate => singleTemplate.templateId);
          setTemplateIdArray(mandatoryTemplateIds);
        }
      }
    } catch (error) {
      console.log('error in init screen ' + error + JSON.stringify(error));
      await setError(true);
    }
  }

  const getPreferenceCenterThemeDetailsByGroupId = async () => {
    try {
      if (consentGroupId) {
        await dispatch(getPreferenceCenterThemeByGroupId(consentGroupId));
        if (preferenceCenterTheme.theme !== undefined) {
          const themeObj = JSON.parse(preferenceCenterTheme.theme);
          document.getElementById("descPara").innerHTML = preferenceCenterTheme.topDescription;
        } else {
          document.getElementById("descPara").innerHTML = `While using ${appName}, your activities create data that will be used by ${appName} to offer you better and more customized services. ${appName} uses this data in accordance with your permission (consent), details of which are clearly mentioned below. We do this, so that YOU are always aware of how we use your data, and YOU have total control on managing the usage of your data`;
        }
        if (preferenceCenterTheme.theme !== undefined) {
          const themeObj = JSON.parse(preferenceCenterTheme.theme);
          document.getElementById("descParaBottom").innerHTML = preferenceCenterTheme.bottomDescription;
        } else {
          document.getElementById("descParaBottom").innerHTML = `By tapping/clicking the ‘Confirm Selection’ button below, you are giving your permission (consent) to ${appName} to use your data in accordance with the parameters mentioned above.`;
        }
        applytheme(themeObj);
      }
    } catch (error) {
      console.log('error in init screen ' + error + JSON.stringify(error));
      await setError(true);
    }
  }

  const callAPI = async () => {
    try {
      await dispatch(getProfileData(clientId));
    } catch (error) {
      console.error(error);
    }
  }

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  const applytheme = async (theme) => {
    const app = document.getElementById("appColor").style;
    app.backgroundColor = theme.card.backgroundColor;
    app.width = theme.card.width;
    const panelHead1 = document.getElementById("panel-head1").style;
    const panelHead2 = document.getElementById("panel-head2").style;
    panelHead1.backgroundColor = theme.panelHead1.backgroundColor;
    panelHead1.padding = theme.panelHead1.padding;
    panelHead1.color = theme.panelHead1.color;
    panelHead1.fontSize = theme.panelHead1.fontSize;
    panelHead2.backgroundColor = theme.panelHead2.backgroundColor;
    panelHead2.padding = theme.panelHead2.padding;
    panelHead2.color = theme.panelHead2.color;
    panelHead2.fontSize = theme.panelHead2.fontSize;
    const button = document.getElementById("button").style;
    button.backgroundColor = theme.button.backgroundColor;
    button.padding = theme.button.padding;
    button.color = theme.button.color;
    button.fontSize = theme.button.fontSize;
  }

  const [loadingMain, setLoadingMain] = useState(true);
  const [fileUrl, setFileUrl] = useState('');
  const [fileType, setFileType] = useState('application/pdf'); // Default to PDF

  // useEffect(() => {
  //   if (noticeFileUrl) {
  //     let mimeType = 'application/pdf'; // Default MIME type
  //     let extension = '.pdf'; // Default file extension

  //     // Here you would add logic to detect the file type. 
  //     // For example, checking the file name or using a header value.
  //     // For now, let's assume the following logic as an example:

  //     if (noticeFileUrl.startsWith('UEsDB')) {
  //       mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'; // Word document
  //       extension = '.docx';
  //     } else if (noticeFileUrl.startsWith('JVBERi0xLjM')) {
  //       mimeType = 'application/pdf'; // PDF file
  //       extension = '.pdf';
  //     } else {
  //       mimeType = 'text/plain'; // Fallback to plain text
  //       extension = '.txt';
  //     }

  //     // Set the MIME type based on detected file type
  //     setFileType(mimeType);

  //     // Decode the Base64 string
  //     const byteCharacters = atob(noticeFileUrl);

  //     // Create a typed array from the binary string
  //     const byteNumbers = new Array(byteCharacters.length);
  //     for (let i = 0; i < byteCharacters.length; i++) {
  //       byteNumbers[i] = byteCharacters.charCodeAt(i);
  //     }

  //     const byteArray = new Uint8Array(byteNumbers);

  //     // Create a Blob from the byte array
  //     const blob = new Blob([byteArray], { type: mimeType });

  //     // Create an object URL from the Blob
  //     const fileUrl = URL.createObjectURL(blob);

  //     // Set the generated URL in state
  //     setFileUrl(fileUrl);
  //   }
  // }, [noticeFileUrl]);


  const createConsentAPI = async () => {
    // Prepare request body for createConsent API
    const createConsentBody = {
      referenceId: referenceId,
      templateId: ["7311f867-00a9-4a0a-a67e-1e5fba20c982"]
    };

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Cookies.get('token')}`, // Add token from cookies
    };

    try {

      // Call the createConsent API
      const createConsentResponse = await axios.post('http://10.162.6.84:9022/createConsent', createConsentBody, { headers });
      console.log('Create Consent API response:', createConsentResponse.data);

      // Extract callback URL from the createConsent response
      const { callback } = createConsentResponse.data;

      // Navigate to the callback URL
      window.location.href = callback;

    } catch (error) {
      console.error('Error calling APIs:', error);
    }
  };

  // useEffect(() => {
  //   dispatch(generateToken());
  //   let encryptedData = window.location.pathname;
  //   // var encData = encodedParam.split("/");

  //   // let paramObject = JSON.parse(encData);
  //   // clientId = paramObject.clientId;
  //   // clientSecret = paramObject.clientSecret;
  //   // consentGroupId = paramObject.consentGroupId;
  //   // customerId = paramObject.customerId;
  //   // dispatch(saveParamsToState(customerId, clientId, clientSecret, consentGroupId));
  //   toggleLoader();
  //   callAPI();
  //   RedirectAPI(encryptedData);  
  //   createConsentAPI();
  //   getProfileData(clientId);
  //   getDetailByConsentGroupId();
  //   getPreferenceCenterThemeDetailsByGroupId();
  //   setLoadingMain(false);
  // }, []);
  let PanelStyle = {};
  const applyNewTheme = (themeReceived) => {





    let theme = {};
    console.log("themeReceived:", themeReceived);

    try {
      //console.log("cleanedTheme->", cleanedTheme);
      theme = JSON.parse(JSON.parse(themeReceived));
    } catch (error) {
      console.error('Failed to parse theme:', error.message);
      return; // Exit the function if parsing fails
    }

    console.log('Parsed theme:', theme);

    // Extract and apply styles from the theme object
    const cardStyle = theme.card || {};
    //alert("theme.card-->" + JSON.stringify(theme));
    //alert("cardStyle" + JSON.stringify(cardStyle));
    // const cardElement = document.getElementById("card01").style;
    if (cardStyle) {

      setBackgroundColor(cardStyle.backgroundColor);
      setTopDescriptionColor(cardStyle.color);
      setBottomDescriptionColor(cardStyle.color);
    }


    //button
    const buttonStyle = theme.button || {};

    //const bu = document.getElementById("card01").style;
    if (buttonStyle) {

      setButtonColor(buttonStyle.color);
      setButtonBackgroundColor(buttonStyle.backgroundColor);

    }

    //Panels
    PanelStyle = theme.panel || {};
    //alert("PanelStyle->" + JSON.stringify(PanelStyle));
    // if (Panel1Style) {

    //   setPanel1Color(Panel1Style.color);
    //   setPanel1BackgroundColor(Panel1Style.backgroundColor);
    // }

    // const Panel2Style = theme.panelHead2 || {};

    // if (Panel2Style) {

    //   setPanel2Color(Panel2Style.color);
    //   setPanel2BackgroundColor(Panel2Style.backgroundColor);

    // }
    const panelHeadNew1 = document.querySelector(".accordion-button");
    const panelHeadNew2 = document.querySelector(".accordion-button");

    if (panelHeadNew1 !== null && panelHeadNew2 !== null) {
      panelHeadNew1.style.backgroundColor = PanelStyle.backgroundColor;
      //alert("PanelStyle->" +PanelStyle.backgroundColor);
      panelHeadNew2.style.backgroundColor = PanelStyle.backgroundColor;
      panelHeadNew1.style.color = PanelStyle.color;
      panelHeadNew2.style.color = PanelStyle.color;

    }

    const headerText = consentText.header.replace(/<\/?[^>]+(>|$)|&[^;]+;/g, " ");
    const footerText = consentText.footer.replace(/<\/?[^>]+(>|$)|&[^;]+;/g, " ");

    setTopDescVal(headerText);
    setBottomDescVal(footerText);
    // Additional style application logic can be added here
    // if (consentText != null) {
    //   const topDescriptionElement = document.getElementById("topDescription");
    //   if (topDescriptionElement) {
    //     alert("consentText.header->" + consentText.header)
    //     topDescriptionElement.innerHTML = consentText.header;
    //   }
    // }
    setLoadingMain(false); // Hide loader
  }


  useEffect(() => {
    if (originalEncryptedData) {
      setLoadingMain(true); // Show loader
      dispatch(callRedirectAPI(originalEncryptedData, clientIdcallRedirectAPI))
        .then(() => {
          setShowModal(true); // Show modal if API call is successful
          applyNewTheme(themeRedirectApiCall);
          pdfURL();
        })
        .catch((error) => {
          console.error("Error in RedirectAPI:", error);
        })

    }
  }, [dispatch, originalEncryptedData]);


  const handleClose = () => {
    setShowModal(false);
  };

  // Check if any item meets the conditions to be hidden for the first Accordion
  const shouldRenderAccordionItem1 = Array.isArray(templates) && templates !== null && templates.some(item =>
    (!item.templateDetails.shared && item.templateDetails.mandatory) ||
    (!item.templateDetails.shared && !item.templateDetails.mandatory)
  );

  // Check if any item meets the conditions to be hidden for the second Accordion
  const shouldRenderAccordionItem2 = Array.isArray(templates) && templates !== null && templates.some(item =>
    (item.templateDetails.shared && item.templateDetails.mandatory) || (item.templateDetails.shared && !item.templateDetails.mandatory)
  );


  return (
    <>
      {/* Custom Scrollbar CSS */}
      <style>
        {`
          /* For the inset look */
          ::-webkit-scrollbar {
            width: 12px;
          }

          ::-webkit-scrollbar-track {
            background: ${backgroundColor};
            box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            border-radius: 10px;
          }

          ::-webkit-scrollbar-thumb {
            background: ${backgroundColor}; /* Example with red color */
            border-radius: 10px;
            box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
          }

          ::-webkit-scrollbar-thumb:window-inactive {
            background: ${backgroundColor};
          }

          /* For Firefox */
          * {
            scrollbar-width: thin;
            scrollbar-color: ${backgroundColor};
          }

          /* Adjust scrollbar for the modal specifically */
          .scrollable-modal {
            overflow-y: auto;
            max-height: 100vh;
            padding-right: 10px;
          }
        `}
      </style>
      {loadingMain && (
        <div id="pre-load">
          <div id="loader" className="loader">
            <div className="loader-container">
              <div className="loader-icon">
                <img
                  src="Img/loader-icon.png"
                  alt='loader'
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {!loadingMain && (
        <BootstrapDialog
          onClose={(event, reason) => {
            if (reason === "backdropClick" || reason === "escapeKeyDown") {
              return;
            }
            setShowModal(false); // Close only if it's not due to backdrop click or escape key
          }}
          open={showModal}
          disableEscapeKeyDown
          maxWidth={false} // Set this to false to allow custom width
          sx={{
            '& .MuiDialog-paper': {
              width: '50%', // Set the width to 70%
              maxWidth: 'none', // Prevent it from using maxWidth prop
              height: '100%',
              borderRadius: '16px'
            },
          }}
        >
          {/* <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton> */}
          <DialogContent id='card01' className="scrollable-modal" style={{ 'backgroundColor': backgroundColor }}>
            <div className='row'>
              <div className='col-md-12'>
                <div className='pop-logo mb-4' style={{ display: 'flex' }}>
                  <img
                    src={logoToDisplay}
                    alt="Logo"
                    style={{ height: '50px' }}
                  />
                  <h3 className='pop-title' style={{ fontFamily: 'JioType', fontSize: '25px', marginLeft: '5px', marginTop: '10px' }}>{appNameRedirectApiCall}</h3>
                </div>
                {/* <h3 className='pop-title'>Manage Consent Preferences</h3> */}
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 my-4'>
                <div className="">
                  <div className='card-body'>
                    {topDescVal && <div id="topDescription" style={{ 'color': topDescriptionColor, fontSize: '13px' }}>{topDescVal}</div>}
                  </div>
                </div>
              </div>
              <div className='col-md-12'>
                <Accordion defaultActiveKey="0" className='pop-custom-accordion'>
                  {shouldRenderAccordionItem1 && (
                    <Accordion.Item eventKey="0">
                      <Accordion.Header id='panelHeadNew1' style={{ backgroundColor: PanelStyle.backgroundColor, color: PanelStyle.color, fontSize: '14px' }}>Data used by {appNameRedirectApiCall}</Accordion.Header>
                      <Accordion.Body className='px-0'>
                        {templates && templates.map((item, key) => {
                          if (item.templateDetails.mandatory && !item.templateDetails.shared) {
                            return (
                              <div key={key} className='row align-items-center mb-0 mb-md-4'>
                                <div className='col-6 mb-4'>
                                  <h3 style={{ margin: '0', color: '#000', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>
                                    <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Template Name</span>
                                    {item.nickName}
                                  </h3>
                                </div>
                                <div className='col-6 mb-4 text-end'>
                                  <span className='status active'>Always Active</span>
                                </div>
                                <div className='col-12'>
                                  <div className='pro-box'>
                                    <div style={{ padding: '20px', background: "#F7F9FD", borderTopLeftRadius: '6px', borderTopRightRadius: '6px', borderBottom: '1px solid #ccc' }}>
                                      <div className='row'>
                                        <div className='col-md-3'>
                                          <h4 style={{ margin: '0', color: '#000', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Used By</span>
                                            {item.templateDetails.dataConsumer.join(', ')}
                                          </h4>
                                        </div>
                                        <div className='col-md-3'>
                                          <h4 style={{ margin: '0', color: '#000', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Data Shared</span>
                                            {item.templateDetails.consentTypes.map((ele, key) => (
                                              <React.Fragment key={key}>{ele}{key === item.templateDetails.consentTypes.length - 1 ? '' : ', '}</React.Fragment>
                                            ))}
                                          </h4>
                                        </div>
                                        <div className='col-md-6'>
                                          <h4 style={{ margin: '0', color: '#000', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Duration</span>
                                            {item.templateDetails.consentValidity.value} {item.templateDetails.consentValidity.unit}
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                    <div style={{ padding: '20px', background: "#E3F0FD", borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', borderBottom: 'none' }}>
                                      <div className='row'>
                                        <div className='col-md-6'>
                                          <h4 style={{ margin: '0', color: '#000', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Purpose</span>
                                            {item.templateDetails.purpose.map((ele, key) => (
                                              <React.Fragment key={key}>{ele}{key === item.templateDetails.purpose.length - 1 ? '' : ', '}</React.Fragment>
                                            ))}
                                          </h4>
                                        </div>
                                        {/* <div className='col-md-6'>
                                      <h4 style={{ margin: '0', color: '#000', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>
                                        <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Purpose</span>
                                        {item.templateDetails.purpose.map((ele, key) => (
                                              <React.Fragment key={key}>{ele}{key === item.templateDetails.purpose.length - 1 ? '' : ', '}</React.Fragment>
                                            ))}
                                      </h4>
                                    </div> */}
                                        <div className='col-md-6'>
                                          <h4 style={{ margin: '0', color: '#000', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Data Item</span>
                                            {item.templateDetails.dataTypes.join(', ')}
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          } else if (!item.templateDetails.mandatory && !item.templateDetails.shared) {
                            return (
                              <div key={key} className='row align-items-center mb-0 mb-md-4'>
                                <div className='col-6 mb-4'>
                                  <h3 style={{ margin: '0', color: '#000', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>
                                    <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Template Name</span>
                                    {item.nickName}
                                  </h3>
                                </div>
                                <div className='col-6 mb-4 text-end'>
                                  <Switch 
                                  key={item.templateId}
                                  checked={checkedStates[item.templateId] || false}
                                  id={`inputCheck-${item.templateId}`}
                                  onClick={() => checkOtherEntitiesCheck(item.templateId)} />
                                  {/* <label class="switch">
                                    <input type="checkbox" id="inputCheck" onClick={() => checkOtherEntitiesCheck(item.templateId)} />
                                    <span class="slider"></span>
                                  </label> */}
                                </div>
                                <div className='col-12'>
                                  <div className='pro-box'>
                                    <div style={{ padding: '20px', background: "#F7F9FD", borderTopLeftRadius: '6px', borderTopRightRadius: '6px', borderBottom: '1px solid #ccc' }}>
                                      <div className='row'>
                                        <div className='col-md-3'>
                                          <h4 style={{ margin: '0', color: '#000', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Used By</span>
                                            {item.templateDetails.dataConsumer.join(', ')}
                                          </h4>
                                        </div>
                                        <div className='col-md-3'>
                                          <h4 style={{ margin: '0', color: '#000', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Data Shared</span>
                                            {item.templateDetails.consentTypes.map((ele, key) => (
                                              <React.Fragment key={key}>{ele}{key === item.templateDetails.consentTypes.length - 1 ? '' : ', '}</React.Fragment>
                                            ))}
                                          </h4>
                                        </div>
                                        <div className='col-md-6'>
                                          <h4 style={{ margin: '0', color: '#000', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Duration</span>
                                            {item.templateDetails.consentValidity.value} {item.templateDetails.consentValidity.unit}
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                    <div style={{ padding: '20px', background: "#E3F0FD", borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', borderBottom: 'none' }}>
                                      <div className='row'>
                                        <div className='col-md-6'>
                                          <h4 style={{ margin: '0', color: '#000', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Purpose</span>
                                            {item.templateDetails.purpose.map((ele, key) => (
                                              <React.Fragment key={key}>{ele}{key === item.templateDetails.purpose.length - 1 ? '' : ', '}</React.Fragment>
                                            ))}
                                          </h4>
                                        </div>
                                        {/* <div className='col-md-6'>
                                      <h4 style={{ margin: '0', color: '#000', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>
                                        <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Purpose</span>
                                        {item.templateDetails.purpose.map((ele, key) => (
                                              <React.Fragment key={key}>{ele}{key === item.templateDetails.purpose.length - 1 ? '' : ', '}</React.Fragment>
                                            ))}
                                      </h4>
                                    </div> */}
                                        <div className='col-md-6'>
                                          <h4 style={{ margin: '0', color: '#000', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Data Item</span>
                                            {item.templateDetails.dataTypes.join(', ')}
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                          return null;
                        })}
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                  {shouldRenderAccordionItem2 && (
                    <Accordion.Item eventKey="1">
                      <Accordion.Header id='panelHeadNew2' style={{ backgroundColor: PanelStyle.backgroundColor, color: PanelStyle.color, fontSize: '14px' }}>Data Shared with other entities</Accordion.Header>
                      <Accordion.Body className='px-0'>
                        {templates && templates.map((item, index) => {
                          if (!item.templateDetails.mandatory && item.templateDetails.shared) {
                            return (
                              <div className='row align-items-center mb-0 mb-md-4' key={index}>
                                <div className='col-6' style={{ marginBottom: '0rem' }}>
                                  <h3 style={{ color: '#000', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>
                                    <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Template Name</span>
                                    {item.nickName}
                                  </h3>
                                </div>


                                <div className='col-6 mb-4 text-end'>
                                  <Switch 
                                  key={item.templateId}
                                  checked={checkedStates[item.templateId] || false}
                                  id={`inputCheck-${item.templateId}`}
                                  onClick={() => checkOtherEntitiesCheck(item.templateId)} />
                                  {/* <label class="switch">
                                    <input type="checkbox" id="inputCheck" onClick={() => checkOtherEntitiesCheck(item.templateId)} />
                                    <span class="slider"></span>
                                  </label> */}
                                </div>

                                <div className='col-12'>
                                  <div className='pro-box'>
                                    <div style={{ padding: '20px', background: "#F7F9FD", borderTopLeftRadius: '6px', borderTopRightRadius: '6px', borderBottom: '1px solid #ccc' }}>
                                      <div className='row'>
                                        <div className='col-md-3'>
                                          <h4 style={{ margin: '0', color: '#000', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Data Shared With</span>
                                            {item.templateDetails.dataConsumer.join(', ')}
                                          </h4>
                                        </div>
                                        <div className='col-md-3'>
                                          <h4 style={{ margin: '0', color: '#000', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Data Shared</span>
                                            {item.templateDetails.consentTypes.join(', ')}
                                          </h4>
                                        </div>
                                        <div className='col-md-6'>
                                          <h4 style={{ margin: '0', color: '#000', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Duration</span>
                                            {item.templateDetails.consentValidity.value} {item.templateDetails.consentValidity.unit}
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                    <div style={{ padding: '20px', background: "#E3F0FD", borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', borderBottom: 'none' }}>
                                      <div className='row'>
                                        <div className='col-md-6'>
                                          <h4 style={{ margin: '0', color: '#000', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Purpose</span>
                                            {item.templateDetails.purpose.map((ele, key) => (
                                              <React.Fragment key={key}>{ele}{key === item.templateDetails.purpose.length - 1 ? '' : ', '}</React.Fragment>
                                            ))}
                                          </h4>
                                        </div>


                                        <div className='col-md-6'>
                                          <h4 style={{ margin: '0', color: '#000', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Data Item</span>
                                            {item.templateDetails.dataTypes.join(', ')}
                                          </h4>
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                          else if (item.templateDetails.mandatory && item.templateDetails.shared) {
                            return (
                              <div className='row align-items-center mb-0 mb-md-4' key={index}>
                                <div className='col-6 mb-4'>
                                  <h3 style={{ margin: '0', color: '#000', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>
                                    <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Template Name</span>
                                    {item.nickName}
                                  </h3>
                                </div>

                                <div className='col-6 mb-4 text-end'>
                                  <span className='status active'>Always Active</span>
                                </div>

                                <div className='col-12'>
                                  <div className='pro-box'>
                                    <div style={{ padding: '20px', background: '#F7F9FD', borderTopLeftRadius: '6px', borderTopRightRadius: '6px', borderBottom: '1px solid #ccc' }}>
                                      <div className='row'>
                                        <div className='col-md-3'>
                                          <h4 style={{ margin: '0', color: '#000', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Data Shared With</span>
                                            {(item.templateDetails.dataConsumer || []).map((ele, key) => (
                                              <React.Fragment key={key}>{ele}{key === item.templateDetails.dataConsumer.length - 1 ? '' : ', '}</React.Fragment>
                                            ))}
                                          </h4>
                                        </div>
                                        <div className='col-md-3'>
                                          <h4 style={{ margin: '0', color: '#000', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Data Shared</span>
                                            {item.templateDetails.consentTypes.map((ele, key) => (
                                              <React.Fragment key={key}>{ele}{key === item.templateDetails.consentTypes.length - 1 ? '' : ', '}</React.Fragment>
                                            ))}
                                          </h4>
                                        </div>
                                        <div className='col-md-6'>
                                          <h4 style={{ margin: '0', color: '#000', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Duration</span>
                                            {item.templateDetails.consentValidity.value} {item.templateDetails.consentValidity.unit}
                                          </h4>
                                        </div>
                                        {/* <div className='col-md-3'>
                                          <h4 style={{ margin: '0', color: '#000', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Purpose</span>
                                            {item.templateDetails.purpose.map((ele, key) => (
                                              <React.Fragment key={key}>{ele}{key === item.templateDetails.purpose.length - 1 ? '' : ', '}</React.Fragment>
                                            ))}
                                          </h4>
                                        </div> */}
                                      </div>
                                    </div>

                                    <div style={{ padding: '20px', background: "#E3F0FD", borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', borderBottom: 'none' }}>
                                      <div className='row'>
                                        <div className='col-md-6'>
                                          <h4 style={{ margin: '0', color: '#000', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Purpose</span>
                                            {item.templateDetails.purpose.map((ele, key) => (
                                              <React.Fragment key={key}>{ele}{key === item.templateDetails.purpose.length - 1 ? '' : ', '}</React.Fragment>
                                            ))}
                                          </h4>
                                        </div>

                                        <div className='col-md-6'>
                                          <h4 style={{ margin: '0', color: '#000', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Data Item</span>
                                            {item.templateDetails.dataTypes.map((ele, key) => (
                                              <React.Fragment key={key}>{ele}{key === item.templateDetails.dataTypes.length - 1 ? '' : ', '}</React.Fragment>
                                            ))}
                                          </h4>
                                        </div>

                                      </div>
                                    </div>

                                  </div>
                                </div>


                              </div>

                            );
                          }
                          return null;
                        })}
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                </Accordion>
              </div>
            </div>
            <div className='row mt-4'>
              <div className='col-md-9' style={{ width: '73%' }}>
                {bottomDescVal && <div style={{ 'color': bottomDescriptionColor, fontSize: '12px' }}>{bottomDescVal}</div>}
              </div>
              <div className='col-md-3 text-end'>
                {/* <button onClick={accept} type="button" id="button" className='btn' style={{ padding: '10px 15px', background: '#0A2885', color: '#FFF', fontSize: '14px', fontWeight: '700' }}>Confirm Selection</button> */}
                <button onClick={accept} type="button" id="button" className="btn" style={{ padding: '10px 15px', 'background': buttonBackgroundColor, border: 'none', minWidth: '57%', textAlign: '-webkit-center', padding: '10px 15px', fontWeight: '700', fontSize: '14px', 'color': buttonColor, borderRadius: '20px' }}><font id="btnfont">Confirm Selection</font><img src="/consentLoader.gif" id="consentLoader" className="img-responsive p-0" style={{ height: '35px', display: 'none' }} /></button>
              </div>
            </div>
            <div className="powered-by-footer" style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
              <small style={{ fontSize: 11, color: '#888' }}>Powered by<br /></small>
              <img src="/Jio-Logo-Colour-Reliance-Midnight-512.png" id="jioLogo" className="img-responsive p-0" style={{ height: '20px', marginLeft: '5px', marginRight: '5px' }} />
              <small style={{ fontSize: 11, fontWeight: 'bold' }}>Consent Management Platform</small>
              <div style={{ marginLeft: 'auto' }}>
                <a href={blobUrl} target="_blank" rel="noopener noreferrer" style={{ fontSize: 11, textDecoration: 'none', color: '#1a73e8' }}>Privacy Notice</a>
                {/* <a style={{ fontSize: 11, textDecoration: 'none', color: '#1a73e8' }}>Privacy Policy</a> &nbsp;|&nbsp;
              <a style={{ fontSize: 11, textDecoration: 'none', color: '#1a73e8' }}>Terms & Conditions</a> */}
              </div>
            </div>
          </DialogContent>
        </BootstrapDialog>
      )}
    </>
  );
};

export default Main;